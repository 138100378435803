



























































































import ModuleConfigSettings from '../../../components/ModuleConfigSettings.vue'
import ModerationLimitItem from './components/ModerationLimitItem.vue'
import ModerationLimitList from './components/ModerationLimitList.vue'
import CommandLimitHandlerModuleBuilder from './CommandLimitHandlerModuleBuilder'
import {
  FrontendCommandLimit,
  FrontendCommandLimits
} from '@/includes/logic/Modules/modules/modules/CommandLimitHandlerModule/types'
import { BaseViewActionType } from '@/includes/types/Enums'
import ModuleConfigSettingsWrapper from '@/includes/logic/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import { InputSetups } from "@/mixins/input-setups";
import {
  state,
  frontendLimitsToRaw, addLocalLimit,
  updateLocalLimit, newFrontendLimitModel,
  isWhitelistSelected, isLimitAllPermission,
  deleteLimit, isLimitNonePermission, setFrontendLimits, setSelectedLimit
} from './logic'

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'

import { Component, Mixins, VModel, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  data() {
    return {
      state
    }
  },
  methods: {
    isLimitNonePermission,
    deleteLimit,
    isLimitAllPermission,
    isWhitelistSelected
  },
  components: {
    SwitchInput,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
    ModerationLimitList,
    ModerationLimitItem
  },
})
export default class CommandLimitHandlerModuleView extends Mixins(ModuleBindings, InputSetups) {
  @VModel() module!: CommandLimitHandlerModuleBuilder

  @Watch('state.limits')
  onLimitsChange(limits: FrontendCommandLimits) {
    this.module.model.params.command_limits = [ ...frontendLimitsToRaw(limits) ]
  }

  isModalOpen = false

  action: BaseViewActionType = BaseViewActionType.New

  handleCreateClick() {
    this.action = BaseViewActionType.New
    setSelectedLimit(newFrontendLimitModel())

    this.isModalOpen = true
  }

  setLimitToEdit(limit: FrontendCommandLimit) {
    this.action = BaseViewActionType.Edit
    setSelectedLimit(cloneDeep(limit))

    this.isModalOpen = true
  }

  handleOkClick() {
    if (state.selectedLimit) {
      if (this.action === BaseViewActionType.New) {
        addLocalLimit()
      }

      if (this.action === BaseViewActionType.Edit) {
        updateLocalLimit()
      }

      setSelectedLimit(null)
    }
  }

  mounted() {
    setFrontendLimits(this.module.model.params.command_limits)
  }
}
