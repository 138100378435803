
































































import { FrontendCommandLimit } from '@/includes/logic/Modules/modules/modules/CommandLimitHandlerModule/types'
import { InputSetups } from '@/mixins/input-setups'
import SelectOptionsMixin from '@/mixins/SelectOptionsMixin'
import { AdminOrAllPermissions } from '@/includes/types/Enums'
import TagsRow from "@/components/TagRow/TagsRow.vue";

import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import getVSelectOptionByValue from 'piramis-js-utils/lib/getVSelectOptionByValue'
import { BlackWhiteListItemType } from 'piramis-base-components/src/components/BlackWhiteList/types'
import BlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue';

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'

@Component({
  components: {
    TagsRow,
    BlackWhiteListInput
  },
  data() {
    return {
      getVSelectOptionByValue,
      BlackWhiteListItemType
    }
  }
})
export default class ModerationLimitCard extends Mixins(InputSetups, SelectOptionsMixin) {
  @Prop() rule!: FrontendCommandLimit

  @Prop() isLimitAllPermission!: (limit:FrontendCommandLimit) => boolean

  @Prop() isLimitNonePermission!: (limit:FrontendCommandLimit) => boolean

  @Emit()
  setLimitToEdit() {
    return this.rule
  }

  @Emit()
  deleteLimit() {
    return this.rule.guid
  }

  currentUnit = 0

  get unitOptions(): Array<number> {
    return [ StepUnit.Second, StepUnit.Minute, StepUnit.Hour, StepUnit.Day, StepUnit.Week, StepUnit.Month ]
      .map((unit: StepUnit) =>  unit / StepUnit.Second)
  }

  get unitLabel() {
    return this.$tc(`period_simplifier_${ this.currentUnit }`, this.rule.value.interval).toString()
  }

  get permissionsOptions() {
    return this.commandPermissionsOptions.filter(o => o.value !== AdminOrAllPermissions.None)
  }

  calculateCurrentUnit(): void {
    const value = this.rule.value.interval

    if (value !== 0) {
      for (const unitOption of this.unitOptions) {
        const unit: StepUnit = unitOption
        const remainder = value % unit

        if (value >= unit && remainder === 0) {
          this.currentUnit = unit
        }
      }
    } else {
      this.currentUnit = this.unitOptions[0]
    }
  }

  created() {
    this.calculateCurrentUnit()
  }

}
