




















































import { CommandsLimitLogicState, FrontendCommandLimit } from '@/includes/logic/Modules/modules/modules/CommandLimitHandlerModule/types'
import ModerationLimitCard from './ModerationLimitCard.vue'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import AddEntityCard from 'piramis-base-components/src/components/AddEntityCard.vue'

import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component({
  components: {
    ModerationLimitCard,
    EmptyData,
    AddEntityCard
  }
})
export default class ModerationLimitList extends Vue {
  @Prop() limitsList!: CommandsLimitLogicState['limits']

  @Prop() isLimitAllPermission!: (limit:FrontendCommandLimit) => boolean

  @Prop() isLimitNonePermission!: (limit:FrontendCommandLimit) => boolean

  @Emit()
  onAddLimit() {
    return null
  }

  @Emit()
  onDeleteLimit(guid: string) {
    return guid
  }

  @Emit()
  onLimitSelect(limit: FrontendCommandLimit) {
    return limit
  }
}
