



































































































































import { FrontendCommandLimit } from '@/includes/logic/Modules/modules/modules/CommandLimitHandlerModule/types'
import { BaseViewActionType } from '@/includes/types/Enums'
import { InputSetups } from '@/mixins/input-setups'
import SelectOptionsMixin from '@/mixins/SelectOptionsMixin'

import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'
import BlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/BlackWhiteListInput/BlackWhiteListInput.vue'
import NumberInput from 'piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'

import { Component, Emit, Mixins, Prop, PropSync, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      StepUnit,
    }
  },
  components: {
    SelectInput,
    BlackWhiteListInput,
    NumberInput,
    TimeUnitInput,
    NestedContent
  }
})
export default class ModerationLimitItem extends Mixins(InputSetups, SelectOptionsMixin) {
  @VModel() limit!: FrontendCommandLimit

  @PropSync('modalActive') isModalOpen!: boolean

  @Prop() action!: BaseViewActionType

  @Prop() isLimitAllPermission!: (limit:FrontendCommandLimit) => boolean

  @Prop() isWhitelistSelected!: (limit:FrontendCommandLimit) => boolean

  @Emit()
  onOkClick() {
    return null
  }

  @Emit()
  onCancel() {
    return null
  }

  get isSaveButtonDisabled() {
    return !this.intervalDependsOnCount || !this.countDependsOnInterval
  }

  get intervalDependsOnCount() {
    const limit = this.limit

    if (limit) {
      const { interval, count } = limit.value

      if (interval === 0 && count === 0) return true
      return !(interval > 0 && count === 0);
    }

    return false
  }

  get countDependsOnInterval() {
    const limit = this.limit

    if (limit) {
      const { interval, count } = limit.value

      if (interval === 0 && count === 0) return true
      return !(count > 0 && interval === 0);

    }

    return false
  }

  get modalTitle() {
    return this.$t(`modal_action_${ this.action }_title`).toString()
  }
}
