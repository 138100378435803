import {
  CommandLimit, CommandLimits,
  CommandsLimitLogicState, FrontendCommandLimit, FrontendCommandLimits
} from "@/includes/logic/Modules/modules/modules/CommandLimitHandlerModule/types";
import { AdminOrAllPermissions } from "@/includes/types/Enums";
import CommandLimitHandlerModule
  from "@/includes/logic/Modules/modules/modules/CommandLimitHandlerModule/CommandLimitHandlerModule";

import { BlackWhiteListItemType } from "piramis-base-components/src/components/BlackWhiteList/types";
import { IterableItemFactory } from "piramis-base-components/src/shared/utils/IterableItemFactory";

import { cloneDeep } from "lodash";

export const itemFactory = new IterableItemFactory()

export const state: CommandsLimitLogicState = {
  limits: [],
  selectedLimit: null
}

export function getRawLimitModel(): CommandLimit {
  return cloneDeep({
    commands: [],
    permissions: AdminOrAllPermissions.None,
    count: 0,
    interval: 0,
    users: {
      type: BlackWhiteListItemType.Blacklist,
      items: []
    }
  })
}

export function setSelectedLimit(limit: CommandsLimitLogicState['selectedLimit']) {
  state.selectedLimit = limit
}

export function setFrontendLimits(commandLimits: CommandLimitHandlerModule['params']['command_limits']) {
  state.limits = commandLimits.map(l => itemFactory.create(l))
}

export function frontendLimitsToRaw(limits: FrontendCommandLimits) {
  return cloneDeep(limits).map(l => l.value)
}

export const isLimitNonePermission = (limit: CommandsLimitLogicState['selectedLimit']) => !!limit && limit.value.permissions === AdminOrAllPermissions.None
export const isLimitAllPermission = (limit: CommandsLimitLogicState['selectedLimit']) => !!limit && limit.value.permissions === AdminOrAllPermissions.All
export const isWhitelistSelected = (limit: CommandsLimitLogicState['selectedLimit']) => !!limit && limit.value.users.type === BlackWhiteListItemType.Whitelist

export function newFrontendLimitModel(): FrontendCommandLimit {
  return itemFactory.create(getRawLimitModel())
}

export function addLocalLimit () {
  if (state.selectedLimit) {
    state.limits.push(state.selectedLimit)
  }
}

export function updateLocalLimit() {
  const newLimit = state.selectedLimit;

  if (newLimit) {
    const limitIndex = state.limits.findIndex(l => l.guid === newLimit.guid)

    state.limits.splice(limitIndex, 1, newLimit)
  }
}

export function deleteLimit (guid: string) {
  state.limits = state.limits.filter(l => l.guid !== guid)
}
